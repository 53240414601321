var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"form"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Member name'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-autocomplete',{attrs:{"placeholder":_vm.$t('Ex:') + ' Juan Pérez',"keep-first":true,"open-on-focus":true,"field":"client_name","data":_vm.filteredDataObj,"clearable":true},on:{"select":function (option) { return (_vm.selected = option); }},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_vm._v(" "+_vm._s(props.option.client_name)+" ")])])]}}],null,true),model:{value:(_vm.objInsurance.inputMemberNumber),callback:function ($$v) {_vm.$set(_vm.objInsurance, "inputMemberNumber", $$v)},expression:"objInsurance.inputMemberNumber"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Customer ID'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 31111811411'},model:{value:(_vm.objInsurance.identification),callback:function ($$v) {_vm.$set(_vm.objInsurance, "identification", $$v)},expression:"objInsurance.identification"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('policy number'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 00-00-INC-0000000'},model:{value:(_vm.objInsurance.policy_number),callback:function ($$v) {_vm.$set(_vm.objInsurance, "policy_number", $$v)},expression:"objInsurance.policy_number"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Module'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 05'},model:{value:(_vm.objInsurance.module),callback:function ($$v) {_vm.$set(_vm.objInsurance, "module", $$v)},expression:"objInsurance.module"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Currency type'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objInsurance.coin_type),callback:function ($$v) {_vm.$set(_vm.objInsurance, "coin_type", $$v)},expression:"objInsurance.coin_type"}},_vm._l((_vm.coinType),function(option){return _c('option',{key:option.id,domProps:{"value":option.name}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Premium amount'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 300950.09',"type":"number","min":"10","max":"20"},model:{value:(_vm.objInsurance.premium_amount),callback:function ($$v) {_vm.$set(_vm.objInsurance, "premium_amount", $$v)},expression:"objInsurance.premium_amount"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Policy type'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objInsurance.policy_type),callback:function ($$v) {_vm.$set(_vm.objInsurance, "policy_type", $$v)},expression:"objInsurance.policy_type"}},_vm._l((_vm.listTypePolicyInsurance),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.policy_type)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Policy status'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objInsurance.policy_state),callback:function ($$v) {_vm.$set(_vm.objInsurance, "policy_state", $$v)},expression:"objInsurance.policy_state"}},_vm._l((_vm.listTypeStateInsurance),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.policy_state)+" ")])}),0)],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Date'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objInsurance.date_start),callback:function ($$v) {_vm.$set(_vm.objInsurance, "date_start", $$v)},expression:"objInsurance.date_start"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Next payment date'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objInsurance.date_next_payment),callback:function ($$v) {_vm.$set(_vm.objInsurance, "date_next_payment", $$v)},expression:"objInsurance.date_next_payment"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Periodicity'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objInsurance.period_type),callback:function ($$v) {_vm.$set(_vm.objInsurance, "period_type", $$v)},expression:"objInsurance.period_type"}},_vm._l((_vm.listTypePeriodicityInsurance),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.period_type)+" ")])}),0)],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Representative`s information'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"maxlength":"200","placeholder":_vm.$t('Ex:') +
                  ' sherriedb@gmail.com, 2654-3654, 2654-5635, 8720-0827',"type":"textarea"},model:{value:(_vm.objInsurance.representative_data),callback:function ($$v) {_vm.$set(_vm.objInsurance, "representative_data", $$v)},expression:"objInsurance.representative_data"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-full"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Remarks'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea","placeholder":_vm.$t('Ex:') +
                  ' Antes estaba a nombre de la sociedad, ahora esta a nombre de ella y el esposo'},model:{value:(_vm.objInsurance.observation),callback:function ($$v) {_vm.$set(_vm.objInsurance, "observation", $$v)},expression:"objInsurance.observation"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"button btn-save",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v("Guardar")]),_c('b-button',{staticClass:"button btn-cancel",on:{"click":_vm.close}},[_vm._v("Cancelar")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }