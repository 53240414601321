import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Insurance from '@/Models/Insurance'
import http from '@/services/http'
import TypePolicyInsurance from '@/Models/TypePolicyInsurance'
import TypeStateInsurance from '@/Models/TypeStateInsurance'
import TypePeriodicityInsurance from '@/Models/TypePeriodicityInsurance'
import Crm from '@/Models/Crm'
import * as Helpers from '@/helpers'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormInsurance extends Vue {
  @Prop({ required: false, default: null })
  objRow!: Insurance
  @Prop({ required: false, default: null })
  isAction!: string
  //el mae seleccionado
  objInsurance: Insurance = new Insurance()
  loading = false
  date: any = new Date()
  name = ''
  isFileEdit = false
  coinType = [
    { id: 1, name: 'Colones', value: 1 },
    { id: 2, name: 'Dolares', value: 2 },
  ]
  listTypePolicyInsurance: Array<TypePolicyInsurance> = []
  listTypeStateInsurance: Array<TypeStateInsurance> = []
  listTypePeriodicityInsurance: Array<TypePeriodicityInsurance> = []
  listMemberNumber: Array<Crm> = []
  selected!: any
  get is_login(): boolean {
    return authModule.isAuthenticated
  }
  mounted() {
    this.getTypePolicyInsurance()
    this.getTypeStateInsurance()
    this.getTypePeriodicityInsurance()
    this.getNumberMember()
    if (this.objRow && this.isAction == 'edit') {
      if (this.objInsurance.member_number == undefined) {
        this.selected = this.objRow.member_number
      }
      this.$set(this, 'objInsurance', this.objRow)
      this.$set(
        this.objInsurance,
        'inputMemberNumber',
        this.objRow.member_number.client_name,
      )
    }
  }

  get filteredDataObj() {
    const filterData = this.listMemberNumber.filter(
      (option) =>
        option.client_name
          .toString()
          .toLowerCase()
          .indexOf(this.objInsurance.inputMemberNumber) >= 0,
    )
    return filterData
    //return filterData.map((x:any) =>  x.document_type)
  }
  async confirmation() {
    const insurance = this.insuranceForm(this.objInsurance)
    switch (this.isAction) {
      case 'add':
        await this.createobjInsurance(insurance)
        break
      case 'edit':
        console.log('editando')
        await this.updateobjInsurance(insurance)
        break
      default:
        console.log('ups')
        break
    }
  }
  async createobjInsurance(objInsurance: any) {
    try {
      await http.post(`api/insurance/arcr_insurance/`, objInsurance)
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      console.error(error.data)
      this.loading = false
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async updateobjInsurance(objInsurance: any) {
    try {
      await http.put(
        `api/insurance/arcr_insurance/${this.objRow.id}/`,
        objInsurance,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      //console.error(error)
      this.loading = false
      switch (error.response.data.member_number[0]) {
        case 'Ya existe Crm Cliente Archivo con este member number.':
          this.$buefy.toast.open({
            message: `Ya exite un cliente con el mismo numero de miembro`,
            type: 'is-danger',
          })
          break
        default:
          this.$buefy.toast.open({
            message: `Ups!, algo salio mal`,
            type: 'is-danger',
          })
          break
      }
      /* this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: "is-danger",
      }) */
    }
  }
  async getNumberMember() {
    this.loading = true
    try {
      const res = await http.get('api/crm/members_list/')
      if (!res.data) throw new Error(res.data)
      this.listMemberNumber = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      //console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }
  async getTypeStateInsurance() {
    try {
      const res = await http.get(`/api/insurance/insurance_policy_states/`)
      if (!res.data) throw new Error(res.data)
      this.listTypeStateInsurance = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async getTypePolicyInsurance() {
    try {
      const res = await http.get(`/api/insurance/insurance_policy_types/`)
      if (!res.data) throw new Error(res.data)
      this.listTypePolicyInsurance = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async getTypePeriodicityInsurance() {
    try {
      const res = await http.get(`/api/insurance/insurance_period_types/`)
      if (!res.data) throw new Error(res.data)
      this.listTypePeriodicityInsurance = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }

  insuranceForm(objInsurance: Insurance) {
    const insurance = {
      id: objInsurance.id,
      member_number_id: this.selected.member_number,
      identification: objInsurance.identification,
      policy_number: objInsurance.policy_number,
      module: objInsurance.module,
      coin_type: objInsurance.coin_type,
      premium_amount: objInsurance.premium_amount,
      policy_type_id: objInsurance.policy_type,
      policy_state_id: objInsurance.policy_state,
      date_start: Helpers.dateFormat_db(objInsurance.date_start),
      date_next_payment: Helpers.dateFormat_db(objInsurance.date_next_payment),
      period_type_id: objInsurance.period_type,
      representative_data: objInsurance.representative_data,
      observation: objInsurance.observation,
    }
    return insurance
  }
  close() {
    this.objInsurance = new Insurance()
    this.$emit('cleanForm')
  }
}
